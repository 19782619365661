import React from 'react';
import { Helmet } from 'react-helmet-async';
import Adsense from "../../Components/Adsense/adsense";
import Navbar from '../../Components/NavBar/Navbar'
import Footer from '../../Components/Footer/Footer'

const blogPage = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Blogs"
        />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <Navbar />
      <Adsense />
      <Footer />
    </div>
  )
}

export default blogPage
