import React from 'react';
import { Helmet } from 'react-helmet';

const adsense = () => {
  return (
    <div>
          <Helmet>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2948805751157514" crossorigin="anonymous"></script>
          </Helmet>
    </div>
  )
}

export default adsense