import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaUniversity, FaMobile, FaPaypal } from "react-icons/fa";
import Icon from "../../Assets/JWLogoW.png";
import "./partnership.css";

const DonationPage = () => {
  const [selectedOption, setSelectedOption] = useState("bank"); // Set "bank" as the default option

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="donation-container">
      <Helmet>
        <meta
          name="description"
          content="Partner with US"
        />
        <link rel="canonical" href="/partner" />
      </Helmet>
      <div className="picon">
        <img src={Icon} alt="" />
      </div>
      <h1>Thank You For Partnering With Just Worship</h1>
      <i>
        <h3>Select your donation method</h3>
      </i>
      <div className="donation-options">
        <div
          className={`donation-option ${
            selectedOption === "bank" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("bank")}
        >
          <FaUniversity />
          Bank Transfer
        </div>
        <div
          className={`donation-option ${
            selectedOption === "ussd" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("ussd")}
        >
          <FaMobile class />
          USSD
        </div>

        <div
          className={`donation-option ${
            selectedOption === "paypal" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("paypal")}
        >
          <a
            href="http://paypal.me/justworshipintl"
            target="_blank"
            rel="noreferrer"
          >
            <FaPaypal /> Paypal
          </a>
        </div>
      </div>
      <div className="donation-content">
        {selectedOption === "bank" && (
          <div className="instr">
            <h3>Bank Details.</h3>
            <h5>Account Name - JUST WORSHIP MUSIC OUTREACH</h5>
            <h5>Account No - 1016249090 (Naira), 5072330291 (USD).</h5>
            <h5>Sort code - 011150000</h5>
            <h5>Swift - ZEIBNGLA013</h5>
          </div>
        )}
        {selectedOption === "ussd" && (
          <div className="instr">
            <h2>Bank Transfer instructions.</h2>
            <h5>Account Name: JUST WORSHIP MUSIC OUTREACH - ZENITH BANK</h5>
            <p>GT Bank - *737*2*Amount*1016249090#</p>
            <p>Access Bank - *901*Amount*1016249090#</p>
            <p>FCMB - *329*Amount*1016249090#</p>
            <p>Stanbic IBTC - *909*22*Amount*1016249090#</p>
            <p>Zenith Bank - *966*Amount*1016249090#</p>
            <p>First Bank - *894*Amount*1016249090#</p>
            <p>Fidelity Bank - *770*1016249090*Amount#</p>
          </div>
        )}
        {selectedOption === "paypal" && (
          <p>Click the "PayPal" button above to donate via PayPal.</p>
        )}
      </div>
    </div>
  );
};

export default DonationPage;
