import React from 'react';
import { Helmet } from 'react-helmet-async';
import Adsense from "../../Components/Adsense/adsense";
import Navbar from '../../Components/NavBar/Navbar'
import Footer from '../../Components/Footer/Footer'
import "./eventPage.css"

const eventPage = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Upcoming events"
        />
        <link rel="canonical" href="/event" />
      </Helmet>
      <Navbar />
      <Adsense />
    <div className="ePageContent">
    <h1>This is all you need to Know about us</h1>
    </div>
      
      <Footer />
    </div>
  )
}

export default eventPage
